import { combineReducers } from 'redux'

import accountDogReducer from './account/accountDogReducer'
import accountPlanReducer from './account/accountPlanReducer'
import accountDogFlowReducer from './account/flow/dogReducer'
import accountDogPlanReducer from './account/flow/planReducer'
import accountDogShippingReducer from './account/flow/shippingReducer'
import accountSummaryReducer from './accountSummaryReducer'
import adminCacheReducer from './admin/cacheReducer'
import dogReducer from './dogReducer'
import hashReducer from './hashReducer'
import keyReducer from './keyReducer'
import parentReducer from './parentReducer'
import planReducer from './planReducer'
import productReducer from './productReducer'
import shippingReducer from './shippingReducer'
import uiReducer from './uiReducer'
import adminDognamesReducer from './uiReducer'

const reducers = combineReducers({
  dog: dogReducer,
  parent: parentReducer,
  plan: planReducer,
  shippingConfirm: shippingReducer,
  ui: uiReducer,
  key: keyReducer,
  hash: hashReducer,
  products: productReducer,
  accountSummary: accountSummaryReducer,
  accountDog: accountDogReducer,
  accountPlan: accountPlanReducer,
  accountDogFlow: accountDogFlowReducer,
  accountDogPlanFlow: accountDogPlanReducer,
  accountDogShippingFlow: accountDogShippingReducer,
  adminCache: adminCacheReducer,
  adminDognames: adminDognamesReducer
})

export default reducers
