import { OrderType, PlanType } from 'state/types/account/accountPlanType'
import { Action, ActionType } from '../../types/account/accountPlanType'

interface StateType {
  plans: PlanType[] | undefined
  orders: OrderType[] | undefined
  loading: boolean
  planCancelStatus: string
  planPauseStatus: string
  shippingDates: Array<string>
  postReschedulePlanStatus: string
  errorMessage: any
  planFailError: any
  planStatus: any
}

const initialState: StateType = {
  plans: undefined,
  orders: undefined,
  loading: false,
  planCancelStatus: '',
  planPauseStatus: '',
  shippingDates: [],
  postReschedulePlanStatus: '',
  errorMessage: '',
  planFailError: '',
  planStatus: {}
}

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.UPDATE_PLANS_PENDING:
      return { ...state, loading: true }
    case ActionType.UPDATE_PLANS:
      return { ...state, plans: action.payload, loading: false, postReschedulePlanStatus: '' }
    case ActionType.UPDATE_PLANS_FAIL:
      return { ...state, planFailError: action.payload, loading: false }
    case ActionType.UPDATE_ORDERS:
      return { ...state, orders: action.payload }
    case ActionType.FETCH_PLAN_SCHEDULE:
      return { ...state, shippingDates: action.payload, errorMessage: null }
    case ActionType.FAILED_PLAN_SCHEDULE:
      return { ...state, errorMessage: action.payload }
    case ActionType.PAUSE_PLAN_SUCCESS:
      return { ...state, planPauseStatus: 'success' }
    case ActionType.PAUSE_PLAN_FAIL:
      return { ...state, planPauseStatus: 'error', errorMessage: action.payload }
    case ActionType.CANCEL_PLAN_SUCCESS:
      return { ...state, planCancelStatus: 'success' }
    case ActionType.CANCEL_PLAN_FAIL:
      return { ...state, planCancelStatus: 'error', errorMessage: action.payload }
    case ActionType.POST_RESCHEDULE_PLAN_SUCCESS:
      return { ...state, postReschedulePlanStatus: 'success' }
    case ActionType.POST_RESCHEDULE_PLAN_FAIL:
      return { ...state, postReschedulePlanStatus: 'error', errorMessage: action.payload }
    case ActionType.RESET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: null,
        planPauseStatus: '',
        planCancelStatus: '',
        postReschedulePlanStatus: ''
      }
    case ActionType.CANCEL_PAUSE_PLAN_REASON:
      return { ...state, planStatus: action.payload }
    case ActionType.RESET:
      return { ...initialState }
    default:
      return state
  }
}

export default reducer
