export enum ActionType {
  ADD_DOGS_PENDING = 'addDogPending',
  ADD_DOGS_SUCCESS = 'addDogsSuccess',
  ADD_DOGS_FAIL = 'addDogsFail',
  UPDATE_CURRCOUPON_CODE = 'updateCurrCouponCode',
  UPDATE_COUPON_CODE_VALIDITY_PENDING = 'updateCouponCodeValidityPending',
  UPDATE_COUPON_CODE_VALIDITY_SUCCESS = 'updateCouponCodeValiditySuccess',
  UPDATE_CHANGE_STATUS = 'updateChangeStatus',
  ADD_ACCOUNT_DOGS_PENDING = 'addAccountDogPending',
  ADD_ACCOUNT_DOGS_SUCCESS = 'addAccountDogsSuccess',
  ADD_ACCOUNT_DOGS_FAIL = 'addAccountDogsFail',
  UPDATE_ACCOUNT_CHANGE_STATUS = 'updateAccountChangeStatus',
  UPDATE_ADDON_DISCOUNT = 'updateAddonDiscount',
  UPDATE_CUSTOMER_EMAIL = 'updateCustomerEmail',
  RESET_ERROR_MESSAGE = 'resetDogsErrorMessage',
  RESET_LOADING_STATUS = 'resetDogsLoadingStatus',
  RESET = 'resetDogs'
}

export interface DogType {
  customer: {
    email: string
    marketingOptIn: boolean
    marketingCookieConsent?: boolean
  }
  dogs: Array<{
    name: string
    weight: number
    dob: string
    exercise: string
    physique: string
    neutered: string
    gender: string
    breed: string
    ageStage: string
    fussy: string
    medicalConditionOrAllergies: boolean | null
    unknownBreed: boolean | null
  }>
  visitorId: string
  growthbook: any
}

export interface SummaryType {
  name: string
  weight: number
  dob: string
  exercise: string
  physique: string
  neutered: string
  ageStage: string
  kcalPerDay: number
  gramsPerDay: number
}

export interface RecipeAddonType {
  itemId: string
  price: number
  priceDiscounted: number
  quantity?: any
}

export interface PackType {
  gramsPerDay: number
  planLevel: number
  full: {
    planId: number
    numberOfPacks: number
    packSize: number
    weeks: number
    totalValue: number
    costPerDay: number
    costPerGrams: number
  }
  partial: {
    planId: number
    numberOfPacks: number
    packSize: number
    weeks: number
    totalValue: number
    costPerDay: number
  }
  fullTrial: {
    planId: number
    numberOfPacks: number
    packSize: number
    weeks: number
    totalValue: number
    costPerDay: number
  }
  partialTrial: {
    planId: number
    numberOfPacks: number
    packSize: number
    weeks: number
    totalValue: number
    costPerDay: number
  }
}

export interface PlanLevelType {
  addons: Array<RecipeAddonType>
  costPerDay: number
  gramsPerDay: number
  numberOfPacks: number
  packSize: number
  planId: number
  recipes: Array<RecipeAddonType>
  requirementPercent: number
  size: string
  totalValue: number
  weeks: number
}

export interface PackNewType {
  default: string
  gramsPerDay: number
  level: number
  kcalPerDay: number
  hash: string | null
  plans: PlanLevelType[]
}

export interface PackSizeType {
  frequency: Array<number>
  pack: Array<{ size: number; minQuantity: number; maxQuantity: number }>
}

export interface CouponType {
  amount: number
  code: string
  type: string
  uses: number
}

export interface CouponsType {
  couponCodes: CouponType[]
}

interface AddDogsPendingAction {
  type: ActionType.ADD_DOGS_PENDING
}

interface AddDogsSuccessAction {
  type: ActionType.ADD_DOGS_SUCCESS
  payload: {
    dogInfo: DogType
    summary: SummaryType[]
    coupon: CouponType
    couponCodes: CouponsType[]
    isValidCoupon: boolean
    pack: PackType
    packSizes: PackSizeType
    defaultPack: string
    planLevel: number
    packNew: PackNewType
  }
}

interface AddDogsFailAction {
  type: ActionType.ADD_DOGS_FAIL
  payload: {
    errorMessage: {
      e: number
      message: string
      action: string
      url: string | null
    }
  }
}

interface updateCurrCouponCodeAction {
  type: ActionType.UPDATE_CURRCOUPON_CODE
  payload: string
}

interface updateCouponCodeValidityPendingAction {
  type: ActionType.UPDATE_COUPON_CODE_VALIDITY_PENDING
}

interface updateCouponCodeValiditySuccessAction {
  type: ActionType.UPDATE_COUPON_CODE_VALIDITY_SUCCESS
  payload: { isValid: boolean; lastValidCode?: CouponType; lastValidCodes?: CouponsType }
}

interface UpdateChangeAction {
  type: ActionType.UPDATE_CHANGE_STATUS
  payload: boolean
}

interface UpdateAccountChangeAction {
  type: ActionType.UPDATE_ACCOUNT_CHANGE_STATUS
  payload: boolean
}

interface UpdateAddonDiscountAction {
  type: ActionType.UPDATE_ADDON_DISCOUNT
  payload: number
}

interface ResetLoadingStatusAction {
  type: ActionType.RESET_LOADING_STATUS
}

interface UpdateCustomerEmailAction {
  type: ActionType.UPDATE_CUSTOMER_EMAIL
  payload: string
}

interface AddAccountDogsPendingAction {
  type: ActionType.ADD_ACCOUNT_DOGS_PENDING
}

interface AddAccountDogsSuccessAction {
  type: ActionType.ADD_ACCOUNT_DOGS_SUCCESS
  payload: {
    dogInfo: DogType
    summary: SummaryType[]
    coupon: CouponType
    isValidCoupon: boolean
    pack: PackType
    packSizes: PackSizeType
    defaultPack: string
    shippingDates: string[]
    smsOptIn: boolean
  }
}

interface AddAccountDogsFailAction {
  type: ActionType.ADD_ACCOUNT_DOGS_FAIL
}

interface ResetErrorMessageAction {
  type: ActionType.RESET_ERROR_MESSAGE
}

interface ResetAction {
  type: ActionType.RESET
}

export type Action =
  | AddDogsPendingAction
  | AddDogsSuccessAction
  | AddDogsFailAction
  | updateCurrCouponCodeAction
  | updateCouponCodeValiditySuccessAction
  | updateCouponCodeValidityPendingAction
  | UpdateChangeAction
  | AddAccountDogsPendingAction
  | AddAccountDogsSuccessAction
  | AddAccountDogsFailAction
  | UpdateAccountChangeAction
  | UpdateAddonDiscountAction
  | UpdateCustomerEmailAction
  | ResetLoadingStatusAction
  | ResetErrorMessageAction
  | ResetAction
