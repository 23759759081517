import { Action, ActionType } from '../types/uiType'

interface StateType {
  showSideBar: boolean
  spoofMode: boolean
  spoofedUserId: string
  spoofedEmail: string
}

const initialState: StateType = {
  showSideBar: false,
  spoofMode: false,
  spoofedUserId: '',
  spoofedEmail: ''
}

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.UPDATE_SIDEBAR_VISIBILITY:
      return { ...state, showSideBar: action.payload }
    case ActionType.UPDATE_SPOOFBANNER_VISIBILITY:
      return { ...state, spoofMode: action.payload }
    case ActionType.UPDATE_SPOOFED_USER_ID:
      return { ...state, spoofedUserId: action.payload }
    case ActionType.UPDATE_SPOOFED_USER_EMAIL:
      return { ...state, spoofedEmail: action.payload }
    default:
      return state
  }
}

export default reducer
