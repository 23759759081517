import {
  Action,
  ActionType,
  CouponType,
  CouponsType,
  DogType,
  PackSizeType,
  PackType,
  SummaryType
} from '../../../types/dogType'

interface StateType {
  dogInfo: DogType[]
  summary: SummaryType[]
  coupon: CouponType
  currCouponCode: string
  isValidCoupon: boolean
  lastValidatedCoupon: CouponType
  lastValidatedCoupons: CouponsType[]
  pack: PackType | undefined
  packSizes: PackSizeType | undefined
  defaultPack: string
  shippingDates: string[]
  loading: boolean
  error: boolean
  changed: boolean
  treatDiscount: number | undefined
}

const initialState: StateType = {
  dogInfo: {
    dogs: [1, 2, 3, 4, 5].map(() => ({
      name: '',
      weight: 0,
      dob: '',
      exercise: '',
      physique: '',
      neutered: '',
      gender: '',
      breed: '',
      ageStage: ''
    })),
    customer: { email: '', marketingOptIn: ['Yes'] }
  },
  summary: [],
  coupon: { amount: 0, code: '', type: '' },
  isValidCoupon: false,
  lastValidatedCoupon: { amount: 0, code: '', type: '' },
  lastValidatedCoupons: [],
  currCouponCode: '',
  pack: undefined,
  packSizes: undefined,
  defaultPack: '',
  shippingDates: [],
  loading: false,
  error: false,
  changed: false,
  treatDiscount: undefined
}

const addDogsReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.ADD_ACCOUNT_DOGS_PENDING:
      return { ...state, loading: true }
    case ActionType.ADD_ACCOUNT_DOGS_SUCCESS:
      return {
        ...state,
        dogInfo: action.payload.dogInfo,
        summary: action.payload.summary,
        coupon: action.payload.coupon,
        isValidCoupon: action.payload.isValidCoupon,
        pack: action.payload.pack,
        packSizes: action.payload.packSizes,
        defaultPack: action.payload.defaultPack,
        shippingDates: action.payload.shippingDates,
        loading: false,
        changed: true
      }
    case ActionType.ADD_ACCOUNT_DOGS_FAIL:
      return { ...state, loading: false, error: true }
    case ActionType.UPDATE_ACCOUNT_CHANGE_STATUS:
      return { ...state, changed: action.payload }
    case ActionType.UPDATE_ADDON_DISCOUNT:
      return { ...state, treatDiscount: action.payload }
    case ActionType.RESET_ERROR_MESSAGE:
      return { ...state, error: false }
    case ActionType.RESET:
      return { ...initialState }
    default:
      return state
  }
}

export default addDogsReducer
