import {
  Action,
  ActionType,
  CouponType,
  CouponsType,
  DogType,
  PackNewType,
  PackSizeType,
  PackType,
  SummaryType
} from '../types/dogType'

interface StateType {
  dogInfo: DogType
  summary: SummaryType[]
  coupon: CouponType
  couponCodes: CouponsType[]
  currCouponCode: string
  isValidCoupon: boolean
  lastValidatedCoupon: CouponType
  lastValidatedCoupons: CouponsType[]
  pack: PackType | undefined
  packSizes: PackSizeType | undefined
  defaultPack: string
  packNew: PackNewType | undefined
  loading: boolean
  error: boolean
  changed: boolean
}

const initialState: StateType = {
  dogInfo: {
    dogs: [1, 2, 3, 4, 5].map(() => ({
      name: '',
      weight: 0,
      dob: '',
      exercise: '',
      physique: '',
      neutered: '',
      gender: '',
      breed: '',
      ageStage: '',
      unknownBreed: ''
    })),
    customer: { email: '', marketingOptIn: true },
    growthbook: {}
  },
  summary: [],
  coupon: { amount: 0, code: '', type: '' },
  couponCodes: [],
  isValidCoupon: false,
  lastValidatedCoupon: { amount: 0, code: '', type: '' },
  lastValidatedCoupons: [],
  currCouponCode: '',
  pack: undefined,
  packSizes: undefined,
  defaultPack: '',
  planLevel: '',
  packNew: undefined,
  loading: false,
  error: false,
  changed: false,
  errorMessage: null
}

const addDogsReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.ADD_DOGS_PENDING:
      return { ...state, loading: true }
    case ActionType.ADD_DOGS_SUCCESS:
      return {
        ...state,
        dogInfo: action.payload.dogInfo,
        summary: action.payload.summary,
        coupon: action.payload.coupon,
        couponCodes: action.payload.couponCodes,
        isValidCoupon: action.payload.isValidCoupon,
        pack: action.payload.pack,
        packSizes: action.payload.packSizes,
        defaultPack: action.payload.defaultPack,
        planLevel: action.payload.planLevel,
        packNew: action.payload.packNew,
        loading: false,
        changed: true,
        error: false,
        errorMessage: ''
      }
    case ActionType.ADD_DOGS_FAIL:
      return { ...state, loading: false, error: true, errorMessage: action.payload.errorMessage }
    case ActionType.UPDATE_CURRCOUPON_CODE:
      return { ...state, currCouponCode: action.payload }
    case ActionType.UPDATE_COUPON_CODE_VALIDITY_PENDING:
      return { ...state, loading: true }
    case ActionType.UPDATE_COUPON_CODE_VALIDITY_SUCCESS:
      return {
        ...state,
        isValidCoupon: action.payload.isValid,
        lastValidatedCoupon: action.payload.lastValidCode?.code
          ? action.payload.lastValidCode
          : state.lastValidatedCoupon,
        lastValidatedCoupons: action.payload.lastValidCodes,
        loading: false
      }
    case ActionType.UPDATE_CUSTOMER_EMAIL:
      return { ...state, dogInfo: { ...state.dogInfo, customer: { ...state.dogInfo.customer, email: action.payload } } }
    case ActionType.UPDATE_CHANGE_STATUS:
      return { ...state, changed: action.payload }
    case ActionType.RESET_LOADING_STATUS:
      return { ...state, loading: false }
    case ActionType.RESET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: null
      }
    case ActionType.RESET:
      return { ...initialState }
    default:
      return state
  }
}

export default addDogsReducer
