export enum ActionType {
  GET_ACCOUNT_SUMMARY_PENDING = 'getAccountSummaryPending',
  GET_ACCOUNT_SUMMARY_SUCCESS = 'getAccountSummarySuccess',
  GET_ACCOUNT_SUMMARY_FAIL = 'getAccountSummaryFail',
  GET_CUSTOMER_PENDING = 'getCustomerPending',
  GET_CUSTOMER_SUCCESS = 'getCustomerSuccess',
  GET_CUSTOMER_FAIL = 'getCustomerFail',
  UPDATE_CUSTOMER_PENDING = 'updateCustomerPending',
  UPDATE_CUSTOMER_SUCCESS = 'updateCustomerSuccess',
  UPDATE_CUSTOMER_FAIL = 'updateCustomerFail',
  REQUIRES_3D_SECURE = 'requires3dSecure',
  RESET = 'resetAccountSummary'
}

export interface PlanType {
  status: string
  dogNames: string
  plan: string
  quantity: number
  nextDelivery: string
  nextPayment: string
  redZone: boolean
  validDeliveryDate: boolean
  address: {
    shipping: {
      address1: string
      address2: string
      city: string
      county: string
      postcode: string
    }
  }
}

export interface AccountSummaryType {
  plans: PlanType[]
  customer: {
    customerId: string
    name: string
    surname: string
    email: string
    address: {
      billing: {
        name: string
        surname: string
        address1: string
        address2: string
        city: string
        county: string
        postcode: string
      }
    }
    payment: {
      status: string
      lastFour: string
      expiry: {
        month: number
        year: number
      }
      brand: string
      type: string
    }
  }
}

export interface CustomerDetailsType {
  phone: string
  cf_marketing_consent: string
  forceResetPassword: boolean
  consent: {
    marketing: boolean
    sms_consent: boolean
    mailConsentFirstParty: boolean
    mailConsentThirdParty: boolean
  }
}

interface getAccountSummaryPendingAction {
  type: ActionType.GET_ACCOUNT_SUMMARY_PENDING
}

interface getAccountSummarySuccessAction {
  type: ActionType.GET_ACCOUNT_SUMMARY_SUCCESS
  payload: { AccountSummary: AccountSummaryType }
}

interface getAccountSummaryFailAction {
  type: ActionType.GET_ACCOUNT_SUMMARY_FAIL
}

interface getCustomerPendingAction {
  type: ActionType.GET_CUSTOMER_PENDING
}

interface getCustomerSuccessAction {
  type: ActionType.GET_CUSTOMER_SUCCESS
  payload: { customer: CustomerDetailsType }
}

interface getCustomerFailAction {
  type: ActionType.GET_CUSTOMER_FAIL
}

interface updateCustomerPendingAction {
  type: ActionType.UPDATE_CUSTOMER_PENDING
}

interface updateCustomerSuccessAction {
  type: ActionType.UPDATE_CUSTOMER_SUCCESS
}

interface updateCustomerFailAction {
  type: ActionType.UPDATE_CUSTOMER_FAIL
  payload: object
}

interface requires3dSecureAction {
  type: ActionType.REQUIRES_3D_SECURE
  payload: object
}

interface resetAccountSummary {
  type: ActionType.RESET
}

export type Action =
  | getAccountSummaryPendingAction
  | getAccountSummarySuccessAction
  | getAccountSummaryFailAction
  | getCustomerPendingAction
  | getCustomerSuccessAction
  | getCustomerFailAction
  | updateCustomerPendingAction
  | updateCustomerSuccessAction
  | updateCustomerFailAction
  | requires3dSecureAction
  | resetAccountSummary
