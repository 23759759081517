export enum ActionType {
  UPDATE_DOGS_AND_PLANS_PENDING = 'updateDogsAndPlansPending',
  UPDATE_DOGS = 'updateDogs',
  UPDATE_PLANS = 'updatePlans',
  UPDATE_PLAN_RADIO = 'updatePlanRadio',
  UPDATE_DOGS_TO_DELETE = 'updateDogsToDelete',
  DELETE_DOG_BY_ID_PENDING = 'deleteDogByIdPending',
  DELETE_DOG_BY_ID_SUCCESS = 'deleteDogByIdSuccess',
  DELETE_DOG_BY_ID_FAIL = 'deleteDogByIdFail',
  RESET = 'resetDogsAccount'
}

export interface DogType {
  name: string
  dob: string
  exercise: string
  gender: string
  breed: string
  id: string
  medicalConditionOrAllergies: boolean
  fussy: string
  neutered: boolean
  physique: string
  weight: number
  newDog: boolean
  updatedAt: string
  gramsPerDay?: string
  kcalPerDay?: string
}

interface UpdateDogsAndPlansPendingAction {
  type: ActionType.UPDATE_DOGS_AND_PLANS_PENDING
}

interface UpdateDogAction {
  type: ActionType.UPDATE_DOGS
  payload: DogType[]
}

interface UpdatePlansAction {
  type: ActionType.UPDATE_PLANS
  payload: []
}

interface UpdateDogsToDelete {
  type: ActionType.UPDATE_DOGS_TO_DELETE
  payload: any[]
}

interface UpdatePlanRadioAction {
  type: ActionType.UPDATE_PLAN_RADIO
  payload: string
}

interface DeleteDogByIdPending {
  type: ActionType.DELETE_DOG_BY_ID_PENDING
}

interface DeleteDogByIdSuccess {
  type: ActionType.DELETE_DOG_BY_ID_SUCCESS
}

interface DeleteDogByIdFail {
  type: ActionType.DELETE_DOG_BY_ID_FAIL
}

interface ResetAction {
  type: ActionType.RESET
}

export type Action =
  | UpdateDogsAndPlansPendingAction
  | UpdateDogAction
  | UpdatePlansAction
  | UpdateDogsToDelete
  | UpdatePlanRadioAction
  | DeleteDogByIdPending
  | DeleteDogByIdSuccess
  | DeleteDogByIdFail
  | ResetAction
