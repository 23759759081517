export enum ActionType {
  UPDATE_KEY = 'updateKey',
  RESET = 'resetKey'
}

interface UpdateKeyAction {
  type: ActionType.UPDATE_KEY
  payload: string
  pricingData: any
}

interface ResetAction {
  type: ActionType.RESET
}

export type Action = UpdateKeyAction | ResetAction
