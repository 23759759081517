import { AccountSummaryType, Action, ActionType, CustomerDetailsType } from '../types/accountSummaryType'

interface StateType {
  summary: AccountSummaryType | undefined
  customer: CustomerDetailsType | undefined
  customerDetailsEdited: boolean
  requires3dSecure: boolean
  payment: object | undefined
  loading: boolean
  error: boolean
  errorMessage: any
}

const initialState: StateType = {
  summary: undefined,
  customer: undefined,
  customerDetailsEdited: false,
  requires3dSecure: false,
  payment: undefined,
  loading: false,
  error: false,
  errorMessage: undefined
}

const accountSummaryReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.GET_ACCOUNT_SUMMARY_PENDING:
      return { ...state, loading: true }
    case ActionType.GET_ACCOUNT_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: action.payload,
        customerDetailsEdited: false,
        loading: false,
        changed: true
      }
    case ActionType.GET_ACCOUNT_SUMMARY_FAIL:
      return { ...state, loading: false, error: true }
    case ActionType.REQUIRES_3D_SECURE:
      return {
        ...state,
        requires3dSecure: true,
        customerDetailsEdited: false,
        payment: action.payload,
        loading: false,
        changed: true
      }
    case ActionType.GET_CUSTOMER_PENDING:
      return { ...state, loading: true }
    case ActionType.GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.payload,
        customerDetailsEdited: false,
        loading: false,
        changed: true
      }
    case ActionType.GET_CUSTOMER_FAIL:
      return { ...state, loading: false, error: true }
    case ActionType.UPDATE_CUSTOMER_PENDING:
      return { ...state, customerDetailsEdited: false, loading: true, error: false, errorMessage: undefined }
    case ActionType.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customerDetailsEdited: true,
        requires3dSecure: false,
        loading: false,
        changed: true,
        error: false,
        errorMessage: undefined
      }
    case ActionType.UPDATE_CUSTOMER_FAIL:
      return { ...state, customerDetailsEdited: false, loading: false, error: true, errorMessage: action.payload }
    case ActionType.RESET:
      return { ...initialState }
    default:
      return state
  }
}

export default accountSummaryReducer
