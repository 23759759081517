export enum ActionType {
  GET_PRODUCTS_PENDING = 'getProductsPending',
  GET_PRODUCTS_SUCCESS = 'getProductsSuccess',
  GET_PRODUCTS_FAIL = 'getProductsFail'
}

export interface RecipesAddonsType {
  itemId: string
  name: string
  description: string
  shortDescription: string
  longDescription: string
  highlights: []
  bullets: []
  weight: string
  type: string
  composition: string
  feedingInformation: string
  nutrients: string
  additives: string
  status: string
  calories: number
  rank: number
  range?: string
  bestseller: boolean
  limitedEdition: boolean
  isNew: boolean
  adultOnly: boolean
  images: any
  analytics: any
}

interface getProductsPendingAction {
  type: ActionType.GET_PRODUCTS_PENDING
}

interface getProductsSuccessAction {
  type: ActionType.GET_PRODUCTS_SUCCESS
  payload: { productListHash: string; recipeList: Array<RecipesAddonsType>; addonList: Array<RecipesAddonsType> }
}

interface getProductsFailAction {
  type: ActionType.GET_PRODUCTS_FAIL
}

export type Action = getProductsPendingAction | getProductsSuccessAction | getProductsFailAction
