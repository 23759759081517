import { Action, ActionType, PlanType, RecipeType } from '../types/planType'

interface StateType {
  plan: PlanType
  noOfPacks: number | undefined
  packSize: number | undefined
  weeks: number | undefined
  isDefaultPlan: boolean
  packType: string
  recipesSelected: RecipeType[]
  addonsSelected: RecipeType[]
  fixedOrCustomPlan: string
  loading: boolean
  trialBoxEnabled: boolean
  error: boolean
  changed: boolean
}

const initialState: StateType = {
  plan: { recipes: [], addons: [] },
  noOfPacks: undefined,
  packSize: undefined,
  weeks: undefined,
  isDefaultPlan: true,
  packType: '',
  recipesSelected: [],
  addonsSelected: [],
  fixedOrCustomPlan: '',
  loading: false,
  trialBoxEnabled: true,
  error: false,
  changed: false
}

const postPlanReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.ADD_PLAN_PENDING:
      return { ...state, loading: true }
    case ActionType.ADD_PLAN_SUCCESS:
      return {
        ...state,
        plan: action.payload.plan,
        noOfPacks: action.payload.noOfPacks,
        packSize: action.payload.packSize,
        weeks: action.payload.weeks,
        isDefaultPlan: action.payload.isDefaultPlan,
        packType: action.payload.packType,
        fixedOrCustomPlan: action.payload.fixedOrCustomPlan,
        loading: false,
        changed: true
      }
    case ActionType.ADD_PLAN_FAIL:
      return { ...state, loading: false, error: true }
    case ActionType.ADD_RECIPES_PENDING:
      return { ...state, loading: true }
    case ActionType.ADD_RECIPES_SUCCESS:
      return {
        ...state,
        recipesSelected: action.payload.recipes,
        loading: false,
        changed: action.payload.changed
      }
    case ActionType.ADD_RECIPES_FAIL:
      return { ...state, loading: false, error: true }
    case ActionType.UPDATE_TRIAL_BOX_PENDING:
      return { ...state, loading: true }
    case ActionType.UPDATE_TRIAL_BOX_SUCCESS:
      return {
        ...state,
        trialBoxEnabled: action.payload,
        loading: false
      }
    case ActionType.UPDATE_TRIAL_BOX_FAIL:
      return { ...state, loading: false, error: true }
    case ActionType.REMOVE_RECIPES_PENDING:
      return { ...state, loading: true }
    case ActionType.REMOVE_RECIPES_SUCCESS:
      return {
        ...state,
        recipesSelected: [],
        loading: false,
        changed: false
      }
    case ActionType.REMOVE_RECIPES_FAIL:
      return { ...state, loading: false, error: true }
    case ActionType.PREFILL_SIGNUP_RECIPES:
      return {
        ...state,
        recipesSelected: action.payload,
        loading: false,
        changed: false
      }
    case ActionType.REMOVE_ADDONS_PENDING:
      return { ...state, loading: true }
    case ActionType.REMOVE_ADDONS_SUCCESS:
      return {
        ...state,
        addonsSelected: [],
        loading: false,
        changed: false
      }
    case ActionType.REMOVE_ADDONS_FAIL:
      return { ...state, loading: false, error: true }
    case ActionType.ADD_ADDONS_PENDING:
      return { ...state, loading: true }
    case ActionType.ADD_ADDONS_SUCCESS:
      return {
        ...state,
        addonsSelected: action.payload,
        loading: false,
        changed: true
      }
    case ActionType.ADD_ADDONS_FAIL:
      return { ...state, loading: false, error: true }
    case ActionType.UPDATE_CHANGE_STATUS:
      return { ...state, changed: action.payload }
    case ActionType.RESET:
      return { ...initialState }
    default:
      return state
  }
}

export default postPlanReducer
