export enum ActionType {
  UPDATE_SIDEBAR_VISIBILITY = 'updateSideBarVisibility',
  UPDATE_SPOOFBANNER_VISIBILITY = 'updateSpoofBannerVisibility',
  UPDATE_SPOOFED_USER_ID = 'updateSpoofedUserId',
  UPDATE_SPOOFED_USER_EMAIL = 'updateSpoofedUserEmail'
}

interface UpdateSideBarVisibilityAction {
  type: ActionType.UPDATE_SIDEBAR_VISIBILITY
  payload: boolean
}

interface UpdateSpoofBannerVisibilityAction {
  type: ActionType.UPDATE_SPOOFBANNER_VISIBILITY
  payload: boolean
}

interface UpdateSpoofedUserIdAction {
  type: ActionType.UPDATE_SPOOFED_USER_ID
  payload: string
}

interface UpdateSpoofedUserEmailAction {
  type: ActionType.UPDATE_SPOOFED_USER_EMAIL
  payload: string
}

export type Action =
  | UpdateSideBarVisibilityAction
  | UpdateSpoofBannerVisibilityAction
  | UpdateSpoofedUserIdAction
  | UpdateSpoofedUserEmailAction
