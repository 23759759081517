import { applyMiddleware, createStore as reduxCreateStore } from 'redux'
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import reducers from './reducers'
import storage from './storage'

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, reducers)

export default (preloadedState = {}) => {
  const createStore = reduxCreateStore(persistedReducer, preloadedState, applyMiddleware(thunk))
  return createStore
}
// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export type RootState = ReturnType<typeof reducers>
