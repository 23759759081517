import { Action, ActionType } from '../../types/admin/cacheType'

interface StateType {
  cache: any
}

const initialState: StateType = {
  cache: {}
}

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.FETCH_CACHE:
      return { ...state, cache: action.payload }
    case ActionType.FETCH_CACHE_STATUS:
      return { ...state, cache: action.payload }
    case ActionType.DELETE_CACHE:
      return { ...state, cache: {} }
    case ActionType.POST_CACHE:
      return { ...state, cache: {} }
    default:
      return state
  }
}

export default reducer
