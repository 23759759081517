export enum ActionType {
  ADD_PLAN_PENDING = 'addPlanPending',
  ADD_PLAN_SUCCESS = 'addPlanSuccess',
  ADD_PLAN_FAIL = 'addPlanFail',
  ADD_RECIPES_PENDING = 'addRecipesPending',
  ADD_RECIPES_SUCCESS = 'addRecipesSuccess',
  ADD_RECIPES_FAIL = 'addRecipesFail',
  REMOVE_RECIPES_PENDING = 'removeRecipesPending',
  REMOVE_RECIPES_SUCCESS = 'removeRecipesSuccess',
  REMOVE_RECIPES_FAIL = 'removeRecipesFail',
  REMOVE_ADDONS_PENDING = 'removeAddonsPending',
  REMOVE_ADDONS_SUCCESS = 'removeAddonsSuccess',
  REMOVE_ADDONS_FAIL = 'removeAddonsFail',
  ADD_ADDONS_PENDING = 'addAddonsPending',
  ADD_ADDONS_SUCCESS = 'addAddonsSuccess',
  ADD_ADDONS_FAIL = 'addAddonsFail',
  UPDATE_CHANGE_STATUS = 'updateChangeStatus',
  ADD_ACCOUNT_PLAN_PENDING = 'addAccountPlanPending',
  ADD_ACCOUNT_PLAN_SUCCESS = 'addAccountPlanSuccess',
  ADD_ACCOUNT_PLAN_FAIL = 'addAccountPlanFail',
  ADD_ACCOUNT_CUSTOM_PLAN_PENDING = 'addAccountCustomPlanPending',
  ADD_ACCOUNT_CUSTOM_PLAN_SUCCESS = 'addAccountCustomPlanSuccess',
  ADD_ACCOUNT_CUSTOM_PLAN_FAIL = 'addAccountCustomPlanFail',
  ADD_ACCOUNT_RECIPES_PENDING = 'addAccountRecipesPending',
  ADD_ACCOUNT_RECIPES_SUCCESS = 'addAccountRecipesSuccess',
  ADD_ACCOUNT_RECIPES_FAIL = 'addAccountRecipesFail',
  REMOVE_ACCOUNT_RECIPES_PENDING = 'removeAccountRecipesPending',
  REMOVE_ACCOUNT_RECIPES_SUCCESS = 'removeAccountRecipesSuccess',
  REMOVE_ACCOUNT_RECIPES_FAIL = 'removeAccountRecipesFail',
  REMOVE_ACCOUNT_ADDONS_PENDING = 'removeAccountAddonsPending',
  REMOVE_ACCOUNT_ADDONS_SUCCESS = 'removeAccountAddonsSuccess',
  REMOVE_ACCOUNT_ADDONS_FAIL = 'removeAccountAddonsFail',
  ADD_ACCOUNT_ADDONS_PENDING = 'addAccountAddonsPending',
  ADD_ACCOUNT_ADDONS_SUCCESS = 'addAccountAddonsSuccess',
  ADD_ACCOUNT_ADDONS_FAIL = 'addAccountAddonsFail',
  UPDATE_ACCOUNT_PLAN_EDITED_STATUS = 'updateAccountEditedStatus',
  UPDATE_ACCOUNT_CHANGE_STATUS = 'updateAccountChangeStatus',
  UPDATE_TRIAL_BOX_PENDING = 'updateTrialBoxPendng',
  UPDATE_TRIAL_BOX_SUCCESS = 'updateTrialBoxSuccess',
  UPDATE_TRIAL_BOX_FAIL = 'updateTrialBoxFail',
  PREFILL_ACCOUNT_RECIPES = 'prefillAccountRecipes',
  PREFILL_SIGNUP_RECIPES = 'prefillSignupRecipes',
  PREFILL_ACCOUNT_TREATS = 'prefillAccountTreats',
  POPULATE_RECIPE_AND_TREATS = 'populateRecipeAndTreats',
  POPULATE_TREATS_ONLY = 'populateTreatsOnly',
  RESET_ERROR_MESSAGE = 'resetErrorMessagePlan',
  RESET = 'resetPlan'
}

export interface RecipeAddonType {
  id: string
  name: string
  description: string
  imageUrlNormal: string
  imageUrlHover: string
  type: string
  price: number
  itemId: string
  composition: string
  nutrients: string
  additives: string | undefined
  status: string
  rank: any
  quantity?: any
  range?: string
  bestseller?: boolean
  analytics?: {
    item_list_id?: string
    item_list_name?: string
  }
}

export interface PlanType {
  recipes: Array<RecipeAddonType>
  addons: Array<RecipeAddonType>
  numberOfPacks: number
}

export interface CouponType {
  coupon: string
}

export interface RecipeType {
  id: string
  quantity: number
  price?: number
}

interface AddPlanPendingAction {
  type: ActionType.ADD_PLAN_PENDING
}

interface AddPlanSuccessAction {
  type: ActionType.ADD_PLAN_SUCCESS
  payload: {
    plan: PlanType
    noOfPacks: number | undefined
    packSize: number | undefined
    weeks: number | undefined
    isDefaultPlan: boolean
    packType: string
    fixedOrCustomPlan: string
  }
}

interface AddPlanFailAction {
  type: ActionType.ADD_PLAN_FAIL
}

interface AddRecipesPendingAction {
  type: ActionType.ADD_RECIPES_PENDING
}

interface AddRecipesSuccessAction {
  type: ActionType.ADD_RECIPES_SUCCESS
  payload: { recipes: RecipeType[]; changed: boolean }
}

interface AddRecipesFailAction {
  type: ActionType.ADD_RECIPES_FAIL
}

interface RemoveRecipesPendingAction {
  type: ActionType.REMOVE_RECIPES_PENDING
}

interface RemoveRecipesSuccessAction {
  type: ActionType.REMOVE_RECIPES_SUCCESS
  payload: []
}

interface RemoveRecipesFailAction {
  type: ActionType.REMOVE_RECIPES_FAIL
}

interface RemoveAddonsPendingAction {
  type: ActionType.REMOVE_ADDONS_PENDING
}

interface RemoveAddonsSuccessAction {
  type: ActionType.REMOVE_ADDONS_SUCCESS
}

interface RemoveAddonsFailAction {
  type: ActionType.REMOVE_ADDONS_FAIL
}

interface AddAddonsPendingAction {
  type: ActionType.ADD_ADDONS_PENDING
}

interface AddAddonsSuccessAction {
  type: ActionType.ADD_ADDONS_SUCCESS
  payload: RecipeType[]
}

interface AddAddonsFailAction {
  type: ActionType.ADD_ADDONS_FAIL
}

interface AddAccountPlanPendingAction {
  type: ActionType.ADD_ACCOUNT_PLAN_PENDING
}

interface AddAccountPlanSuccessAction {
  type: ActionType.ADD_ACCOUNT_PLAN_SUCCESS
  payload: {
    plan: PlanType
    noOfPacks: number | undefined
    packSize: number | undefined
    weeks: number | undefined
    isDefaultPlan: boolean
    packType: string
    fixedOrCustomPlan: string
  }
}

interface AddAccountPlanFailAction {
  type: ActionType.ADD_ACCOUNT_PLAN_FAIL
}

interface AddAccountCustomPlanPendingAction {
  type: ActionType.ADD_ACCOUNT_CUSTOM_PLAN_PENDING
}

interface AddAccountCustomPlanSuccessAction {
  type: ActionType.ADD_ACCOUNT_CUSTOM_PLAN_SUCCESS
  payload: {
    plan: PlanType
    noOfPacks: number | undefined
    packSize: number | undefined
    weeks: number | undefined
    isDefaultPlan: boolean
    packType: string
    fixedOrCustomPlan: string
  }
}

interface AddAccountCustomPlanFailAction {
  type: ActionType.ADD_ACCOUNT_CUSTOM_PLAN_FAIL
}

interface AddAccountRecipesPendingAction {
  type: ActionType.ADD_ACCOUNT_RECIPES_PENDING
}

interface AddAccountRecipesSuccessAction {
  type: ActionType.ADD_ACCOUNT_RECIPES_SUCCESS
  payload: RecipeType[]
}

interface AddAccountRecipesFailAction {
  type: ActionType.ADD_ACCOUNT_RECIPES_FAIL
}

interface RemoveAccountRecipesPendingAction {
  type: ActionType.REMOVE_ACCOUNT_RECIPES_PENDING
}

interface RemoveAccountRecipesSuccessAction {
  type: ActionType.REMOVE_ACCOUNT_RECIPES_SUCCESS
  payload: []
}

interface RemoveAccountRecipesFailAction {
  type: ActionType.REMOVE_ACCOUNT_RECIPES_FAIL
}

interface RemoveAccountAddonsPendingAction {
  type: ActionType.REMOVE_ACCOUNT_ADDONS_PENDING
}

interface RemoveAccountAddonsSuccessAction {
  type: ActionType.REMOVE_ACCOUNT_ADDONS_SUCCESS
}

interface RemoveAccountAddonsFailAction {
  type: ActionType.REMOVE_ACCOUNT_ADDONS_FAIL
}

interface AddAccountAddonsPendingAction {
  type: ActionType.ADD_ACCOUNT_ADDONS_PENDING
}

interface AddAccountAddonsSuccessAction {
  type: ActionType.ADD_ACCOUNT_ADDONS_SUCCESS
  payload: RecipeType[]
}

interface AddAccountAddonsFailAction {
  type: ActionType.ADD_ACCOUNT_ADDONS_FAIL
}

interface UpdateAccountPlanEditedAction {
  type: ActionType.UPDATE_ACCOUNT_PLAN_EDITED_STATUS
  payload: boolean
}

interface UpdateAccountChangeAction {
  type: ActionType.UPDATE_ACCOUNT_CHANGE_STATUS
  payload: boolean
}

interface UpdateTrialBoxFailAction {
  type: ActionType.UPDATE_TRIAL_BOX_FAIL
}

interface UpdateTrialBoxPendingAction {
  type: ActionType.UPDATE_TRIAL_BOX_PENDING
}

interface UpdateTrialBoxSuccessAction {
  type: ActionType.UPDATE_TRIAL_BOX_SUCCESS
  payload: boolean
}

interface UpdateChangeAction {
  type: ActionType.UPDATE_CHANGE_STATUS
  payload: boolean
}

interface PrefillAccountRecipes {
  type: ActionType.PREFILL_ACCOUNT_RECIPES
  payload: RecipeType[]
}

interface PrefillSignupRecipes {
  type: ActionType.PREFILL_SIGNUP_RECIPES
  payload: RecipeType[]
}

interface PrefillAccountTreats {
  type: ActionType.PREFILL_ACCOUNT_TREATS
  payload: RecipeType[]
}

interface PopulateRecipeAndTreatsData {
  type: ActionType.POPULATE_RECIPE_AND_TREATS
  payload: any
}

interface PopulateTreatsData {
  type: ActionType.POPULATE_TREATS_ONLY
  payload: any
}

interface ResetErrorMessageAction {
  type: ActionType.RESET_ERROR_MESSAGE
}

interface ResetAction {
  type: ActionType.RESET
}

export type Action =
  | AddPlanPendingAction
  | AddPlanSuccessAction
  | AddPlanFailAction
  | AddRecipesPendingAction
  | AddRecipesSuccessAction
  | AddRecipesFailAction
  | RemoveRecipesPendingAction
  | RemoveRecipesSuccessAction
  | RemoveRecipesFailAction
  | AddAddonsPendingAction
  | AddAddonsSuccessAction
  | AddAddonsFailAction
  | RemoveAddonsPendingAction
  | RemoveAddonsSuccessAction
  | RemoveAddonsFailAction
  | UpdateChangeAction
  | AddAccountPlanPendingAction
  | AddAccountPlanSuccessAction
  | AddAccountPlanFailAction
  | AddAccountCustomPlanPendingAction
  | AddAccountCustomPlanSuccessAction
  | AddAccountCustomPlanFailAction
  | AddAccountRecipesPendingAction
  | AddAccountRecipesSuccessAction
  | AddAccountRecipesFailAction
  | RemoveAccountRecipesPendingAction
  | RemoveAccountRecipesSuccessAction
  | RemoveAccountRecipesFailAction
  | RemoveAccountAddonsPendingAction
  | RemoveAccountAddonsSuccessAction
  | RemoveAccountAddonsFailAction
  | AddAccountAddonsPendingAction
  | AddAccountAddonsSuccessAction
  | AddAccountAddonsFailAction
  | UpdateAccountPlanEditedAction
  | UpdateAccountChangeAction
  | UpdateTrialBoxFailAction
  | UpdateTrialBoxPendingAction
  | UpdateTrialBoxSuccessAction
  | PrefillAccountRecipes
  | PrefillSignupRecipes
  | PrefillAccountTreats
  | PopulateRecipeAndTreatsData
  | PopulateTreatsData
  | ResetErrorMessageAction
  | ResetAction
