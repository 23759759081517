import { Action, ActionType } from '../types/productsType'

interface StateType {
  productListHash: string
  recipeList: []
  addonList: []
}

const initialState: StateType = {
  productListHash: '',
  recipeList: [],
  addonList: []
}

const productReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.GET_PRODUCTS_PENDING:
      return { ...state, loading: true }
    case ActionType.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        productListHash: action.payload.productListHash,
        recipeList: action.payload.recipeList,
        addonList: action.payload.addonList,
        loading: false
      }
    case ActionType.GET_PRODUCTS_FAIL:
      return { ...state, loading: false, error: true }
    default:
      return state
  }
}

export default productReducer
