import { Action, ActionType, PlanType, RecipeType } from '../../../types/planType'

interface StateType {
  plan: PlanType
  noOfPacks: number | undefined
  packSize: number | undefined
  weeks: number | undefined
  isDefaultPlan: boolean
  packType: string
  recipesSelected: RecipeType[]
  addonsSelected: RecipeType[]
  fixedOrCustomPlan: string
  loading: boolean
  error: boolean
  changed: boolean
  planEdited: boolean
}

const initialState: StateType = {
  plan: { recipes: [], addons: [] },
  noOfPacks: undefined,
  packSize: undefined,
  weeks: undefined,
  isDefaultPlan: true,
  packType: '',
  recipesSelected: [],
  addonsSelected: [],
  fixedOrCustomPlan: '',
  loading: false,
  error: false,
  changed: false,
  planEdited: false
}

const postPlanReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.ADD_ACCOUNT_PLAN_PENDING:
      return { ...state, loading: true }
    case ActionType.ADD_ACCOUNT_PLAN_SUCCESS:
      return {
        ...state,
        plan: action.payload.plan,
        noOfPacks: action.payload.noOfPacks,
        packSize: action.payload.packSize,
        weeks: action.payload.weeks,
        isDefaultPlan: action.payload.isDefaultPlan,
        packType: action.payload.packType,
        fixedOrCustomPlan: action.payload.fixedOrCustomPlan,
        loading: false,
        changed: true
      }
    case ActionType.ADD_ACCOUNT_PLAN_FAIL:
      return { ...state, loading: false, error: true }
    case ActionType.ADD_ACCOUNT_CUSTOM_PLAN_PENDING:
      return { ...state, loading: true }
    case ActionType.ADD_ACCOUNT_CUSTOM_PLAN_SUCCESS:
      return {
        ...state,
        plan: action.payload.plan,
        noOfPacks: action.payload.noOfPacks,
        packSize: action.payload.packSize,
        weeks: action.payload.weeks,
        isDefaultPlan: action.payload.isDefaultPlan,
        packType: action.payload.packType,
        fixedOrCustomPlan: action.payload.fixedOrCustomPlan,
        loading: false,
        changed: false
      }
    case ActionType.ADD_ACCOUNT_CUSTOM_PLAN_FAIL:
      return { ...state, loading: false, error: true }
    case ActionType.ADD_ACCOUNT_RECIPES_PENDING:
      return { ...state, loading: true }
    case ActionType.ADD_ACCOUNT_RECIPES_SUCCESS:
      return {
        ...state,
        recipesSelected: action.payload,
        loading: false,
        changed: true
      }
    case ActionType.PREFILL_ACCOUNT_RECIPES:
      return {
        ...state,
        recipesSelected: action.payload,
        loading: false,
        changed: false
      }
    case ActionType.ADD_ACCOUNT_RECIPES_FAIL:
      return { ...state, loading: false, error: true }
    case ActionType.REMOVE_ACCOUNT_RECIPES_PENDING:
      return { ...state, loading: true }
    case ActionType.REMOVE_ACCOUNT_RECIPES_SUCCESS:
      return {
        ...state,
        recipesSelected: [],
        loading: false,
        changed: false
      }
    case ActionType.REMOVE_ACCOUNT_RECIPES_FAIL:
      return { ...state, loading: false, error: true }
    case ActionType.REMOVE_ACCOUNT_ADDONS_PENDING:
      return { ...state, loading: true }
    case ActionType.REMOVE_ACCOUNT_ADDONS_SUCCESS:
      return {
        ...state,
        addonsSelected: [],
        loading: false,
        changed: false
      }
    case ActionType.REMOVE_ACCOUNT_ADDONS_FAIL:
      return { ...state, loading: false, error: true }
    case ActionType.ADD_ACCOUNT_ADDONS_PENDING:
      return { ...state, loading: true }
    case ActionType.ADD_ACCOUNT_ADDONS_SUCCESS:
      return {
        ...state,
        addonsSelected: action.payload,
        loading: false,
        changed: true
      }
    case ActionType.PREFILL_ACCOUNT_TREATS:
      return {
        ...state,
        addonsSelected: action.payload,
        loading: false,
        changed: false
      }
    case ActionType.POPULATE_RECIPE_AND_TREATS:
      return {
        ...state,
        plan: action.payload.plan,
        noOfPacks: action.payload.noOfPacks,
        packSize: action.payload.packSize,
        weeks: action.payload.weeks,
        isDefaultPlan: action.payload.isDefaultPlan,
        packType: action.payload.packType,
        fixedOrCustomPlan: action.payload.fixedOrCustomPlan
      }
    case ActionType.POPULATE_TREATS_ONLY:
      return {
        ...state,
        plan: action.payload.plan
      }
    case ActionType.ADD_ACCOUNT_ADDONS_FAIL:
      return { ...state, loading: false, error: true }
    case ActionType.UPDATE_ACCOUNT_CHANGE_STATUS:
      return { ...state, changed: action.payload }
    case ActionType.UPDATE_ACCOUNT_PLAN_EDITED_STATUS:
      return { ...state, planEdited: action.payload }
    case ActionType.RESET_ERROR_MESSAGE:
      return { ...state, error: false }
    case ActionType.RESET:
      return { ...initialState }
    default:
      return state
  }
}

export default postPlanReducer
