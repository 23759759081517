import { Action, ActionType } from '../types/keyType'

interface StateType {
  key: string
  pricingData: any
}

const initialState: StateType = {
  key: 'dog-details',
  pricingData: ''
}

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.UPDATE_KEY:
      return { ...state, key: action.payload, pricingData: action.pricingData }
    case ActionType.RESET:
      return { ...initialState }
    default:
      return state
  }
}

export default reducer
