import { DogType } from 'state/types/account/accountDogType'
import { Action, ActionType } from '../../types/account/accountDogType'

interface StateType {
  dogs: DogType[]
  plans: []
  planRadio: string
  loading: boolean
  dogsToDelete: string[]
}

const initialState: StateType = {
  dogs: [],
  plans: [],
  planRadio: '',
  loading: false,
  dogsToDelete: []
}

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.UPDATE_DOGS_AND_PLANS_PENDING:
      return { ...state, loading: true }
    case ActionType.UPDATE_DOGS:
      return { ...state, dogs: action.payload, loading: false }
    case ActionType.UPDATE_PLANS:
      return { ...state, plans: action.payload, loading: false }
    case ActionType.UPDATE_PLAN_RADIO:
      return { ...state, planRadio: action.payload }
    case ActionType.UPDATE_DOGS_TO_DELETE:
      return { ...state, dogsToDelete: action.payload }
    case ActionType.RESET:
      return { ...initialState }
    default:
      return state
  }
}

export default reducer
