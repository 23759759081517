export enum ActionType {
  FETCH_CACHE = 'fetchCache',
  FETCH_CACHE_STATUS = 'fetchCacheStatus',
  DELETE_CACHE = 'deleteCache',
  POST_CACHE = 'postCache'
}

interface FetchCacheStatusAction {
  type: ActionType.FETCH_CACHE_STATUS
  payload: any
}

interface FetchCacheAction {
  type: ActionType.FETCH_CACHE
  payload: any
}

interface DeleteCacheAction {
  type: ActionType.DELETE_CACHE
  payload: any
}

interface PostCacheAction {
  type: ActionType.POST_CACHE
  payload: any
}

export type Action = FetchCacheAction | DeleteCacheAction | PostCacheAction | FetchCacheStatusAction
