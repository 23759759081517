import { Action, ActionType, OrderSummaryType, ShippingType } from '../types/shippingType'

interface StateType {
  shipping: ShippingType
  loading: boolean
  error: boolean
  changed: boolean
  orderSummary: OrderSummaryType
}

const initialState: StateType = {
  shipping: {
    customer: {
      name: '',
      surname: '',
      email: '',
      marketingOptIn: false,
      telephone: '',
      smsOptIn: false,
      password: ''
    },
    address: {
      shipping: {
        address1: '',
        address2: '',
        city: '',
        county: '',
        postcode: ''
      },
      billing: {
        name: '',
        surname: '',
        address1: '',
        address2: '',
        city: '',
        county: '',
        postcode: ''
      }
    },
    coupon: '',
    notes: '',
    payment: undefined,
    setupIntent: undefined,
    recipes: [],
    addons: [],
    deliveryDate: '',
    plan: {
      id: '',
      type: '',
      level: 0,
      size: '',
      grams: ''
    }
  },
  orderSummary: {
    recipes: [],
    addons: [],
    deliveryDate: ''
  },
  loading: false,
  error: false,
  changed: false,
  errorMessage: null
}

const ShippingReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SUBMIT_SHIPPING_PENDING:
      return { ...state, loading: true }
    case ActionType.SUBMIT_SHIPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        changed: action.payload.changed,
        shipping: action.payload.shippingConfirm,
        errorMessage: ''
      }
    case ActionType.ADD_ORDER_SUMMARY_SUCCESS:
      return {
        ...state,
        orderSummary: action.payload.orderSummary
      }
    case ActionType.SUBMIT_SHIPPING_FAIL:
      return { ...state, loading: false, error: true, errorMessage: action.payload.errorMessage }
    case ActionType.REQUIRES_3D_SECURE:
      return { ...state, loading: true, error: true, setupIntent: action.payload.setupIntent }
    case ActionType.SAVE_SHIPPING_FORM: {
      return {
        ...state,
        shipping: action.payload,
        errorMessage: ''
      }
    }
    case ActionType.VALIDATE_POSTCODE_PENDING:
      return { ...state, loading: true }
    case ActionType.VALIDATE_POSTCODE_SUCCESS:
      return { ...state, errorMessage: '', loading: false }
    case ActionType.VALIDATE_POSTCODE_FAIL:
      return { ...state, loading: false, error: true, errorMessage: action.payload.errorMessage }
    case ActionType.RESET_LOADING_STATUS:
      return { ...state, loading: false }
    case ActionType.RESET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: null,
        error: false
      }
    case ActionType.RESET:
      return { ...initialState }
    default:
      return state
  }
}

export default ShippingReducer
